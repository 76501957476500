var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "information-page" },
    [
      _c(
        "p",
        { staticClass: "page-icon" },
        [
          _c("van-icon", { class: _vm.iconClass, attrs: { name: _vm.vanIcon } })
        ],
        1
      ),
      _c("p", { staticClass: "page-title" }, [
        _vm._v(" " + _vm._s(_vm.pageTitle) + " ")
      ]),
      _vm.lessTitle
        ? _c("p", { staticClass: "page-title" }, [
            _vm._v(" " + _vm._s(_vm.lessTitle) + " ")
          ])
        : _vm._e(),
      _c(
        "van-button",
        {
          attrs: { block: "", type: "info", "native-type": "button" },
          on: {
            click: function($event) {
              return _vm.$router.push({ name: _vm.pathName, query: _vm.query })
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.buttonTitle) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }