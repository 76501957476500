<template>
  <div class="information-page">
    <p class="page-icon">
      <van-icon :name="vanIcon" :class="iconClass" />
    </p>
    <p class="page-title">
      {{ pageTitle }}
    </p>
    <p v-if="lessTitle" class="page-title">
      {{ lessTitle }}
    </p>
    <van-button block type="info" native-type="button" @click="$router.push({ name: pathName,query: query})">
      {{ buttonTitle }}
    </van-button>
  </div>
</template>

<script>
import { Button, Icon } from 'vant'
export default {
  name: 'InformationPage',
  components: {
    [Button.name]: Button,
    [Icon.name]: Icon
  },
  props: {
    buttonTitle: {
      type: String,
      default: ''
    },
    pathName: {
      type: String,
      default: ''
    },
    query: {
      type: Object,
      default () {
        return {}
      }
    },
    pageTitle: {
      type: String,
      default: ''
    },
    lessTitle: {
      type: String,
      default: ''
    },
    vanIcon: {
      type: String,
      default: ''
    },
    iconClass: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style>

</style>
